.social-icon {
    padding: 10px;
    margin: 10px;
  }
  
  .icon-container {
    border: 2px solid black;
    border-radius: 10px;
    padding: 5px;
  }
  .social-link {
    text-decoration: none; /* Elimina el subrayado */
    color: black; /* Cambia el color del texto a negro */
  }

  .social-icon-size {
    font-size: 64px; /* Tamaño para dispositivos móviles */
  }
  
  @media (max-width: 768px) {
    .social-icon-size {
      font-size: 40px; /* Tamaño para otras versiones */
    }
  }
  