.course-card {
border: 1px solid black;
border-radius: 50px;
padding: 10px;
margin-bottom: 20px;
}

.course-card img {
width: 100%;
border-radius: 8px;
}

.course-card h3 {
margin-top: 10px;
}

.course-card p {
font-style: italic;
margin-bottom: 0;
}
  
.center-row {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    justify-content: center;
}

.course-image {
    max-width: 100%;
    max-height: 130px;
}

/* Para dispositivos móviles */
@media (max-width: 767px) {
    .course-image {
      max-width: 100%;
      max-height: 90px;
    }
  } 