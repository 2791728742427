@import url('../fonts/satoshi/css/satoshi.css');

.button-generic {
    background-color: #4494b6;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    border-color: #4494b6;
}

.button-generic:hover {
    background-color: #1568c2;
}

.main-container {
    border: 2px solid black;
    border-radius: 50px;
    padding: 20px;
    max-width: 100vh;
    margin: auto;
}

.satoshi-bold {
    font-family: 'Satoshi-Bold';
}

.satoshi-medium {
    font-family: 'Satoshi-Medium';
}